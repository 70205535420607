*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --clr-dark: rgb(13, 17, 19);
  --clr-light: #fff;
  --clr-accent: rgb(222, 52, 0);
  --clr-accent-dark: rgb(163, 38, 0);
  --clr-secondary: rgb(0, 76, 199);
  --br: 0.5em;
  --transition: transform 200ms cubic-bezier(0.21, 1, 0.81, 1);
}

h1,
h2,
h3,
p {
  margin: 0;
}

img {
  max-width: 100%;
}

.btn {
  cursor: pointer;
  border: 0;
  background: var(--clr-secondary);
  border-radius: 100vw;
  color: var(--clr-light);
  font-weight: bold;
  padding: 0.5em 1.5em;
}

.icon::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.game {
  position: relative;
  /* width: 12.5em; */
  width: 14.5em;
  cursor: pointer;
  color: tan;
}
.technos,
.technos-1,
.technos-2,
.technos-3,
.technos-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
}
.technos img,
.technos-1 img,
.technos-2 img,
.technos-3 img,
.technos-4 img {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

/* go */
.go-wrap {
  text-decoration: none;
}
.go-wrap .go {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cc0202;
  color: white;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  font-weight: 900;
  font-size: 1.1em;
  -webkit-transition: -webkit-transform ease-in-out 150ms;
  transition: -webkit-transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms, -webkit-transform ease-in-out 150ms;
}

.go-wrap:hover .go {
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}

.go-wrap:hover .go::before {
  opacity: 1;
}

.go-wrap .go:hover {
  -webkit-transform: translateY(-15%) scale(1.2);
  transform: translateY(-15%) scale(1.2);
}

.go-wrap .go::before,
.go-wrap .go::after {
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
}

.go-wrap .go::before {
  content: "►";
  font-weight: 900;
  /* font-size: 0.65em; */
  font-size: 1.1em;
  background: #f00;
  display: -ms-grid;
  display: grid;
  place-items: center;
  z-index: 10;
  -webkit-transition: opacity 75ms linear, background-color 100ms linear;
  transition: opacity 75ms linear, background-color 100ms linear;
}

.go-wrap .go:hover::before {
  background: #cc0202;
}

.go-wrap .go:hover::after {
  background: #f00;
  z-index: 1;
  -webkit-animation: pulse 1250ms infinite;
  animation: pulse 1250ms infinite;
}

/* fin go */

.rank {
  position: absolute;
  top: 0;
  right: 1em;
  z-index: 1000;
  font-weight: bold;
  font-size: 1.125rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5em 0.5em 0.75em;
  -webkit-clip-path: polygon(100% 0%, 100% 100%, 50% 85%, 0 100%, 0 0);
  clip-path: polygon(100% 0%, 100% 100%, 50% 85%, 0 100%, 0 0);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.front {
  -webkit-transition: 250ms;
  transition: 250ms;
}

.front .thumbnail {
  border-radius: var(--br);
}

.front .name {
  margin: 0.75em 0;
}

.front .resume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.front .main-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .front .viewers::before {
  content: "\f007";
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.75em;
  margin-right: 0.5em;
} */
.front .technos-1 img,
.front .technos-2 img,
.front .technos-3 img,
.front .technos-4 img {
  border: 2px solid var(--clr-dark);
}

.front .technos-4 img:nth-of-type(1) {
  /* -webkit-transform: translateX(50%); */
  transform: translateX(75%);
  z-index: 7;
  /* z-index: 5; */
}

.front .technos-4 img:nth-of-type(2) {
  /* -webkit-transform: translateX(25%); */
  transform: translateX(50%);
  z-index: 6;
}
.front .technos-4 img:nth-of-type(3) {
  /* -webkit-transform: translateX(25%); */
  transform: translateX(25%);
  z-index: 5;
}

.front .technos-3 img:nth-of-type(1) {
  /* -webkit-transform: translateX(50%); */
  transform: translateX(50%);
  z-index: 3;
  /* z-index: 5; */
}

.front .technos-3 img:nth-of-type(2) {
  /* -webkit-transform: translateX(25%); */
  transform: translateX(25%);
  z-index: 2;
}

.front .technos-2 img:nth-of-type(1) {
  /* -webkit-transform: translateX(25%); */
  transform: translateX(25%);
  z-index: 2;
}

.front h3 {
  color: white;
}
.back {
  opacity: 0;
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.7em;
  -webkit-transform: translateY(35%);
  transform: translateY(35%);
}

.back .components-info {
  -webkit-columns: 2;
  columns: 2;
  -webkit-column-rule: 1px solid rgba(255, 255, 255, 0.25);
  column-rule: 1px solid rgba(255, 255, 255, 0.25);
}

.back .component {
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
}

.back .component span {
  white-space: nowrap;
  font-size: 0.85rem;
  font-weight: normal;
  display: block;
}

.background {
  background: #12162d;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  -webkit-transform: scale(0.2, 0.9);
  transform: scale(0.2, 0.9);
  opacity: 0;
  border-radius: var(--br);
  overflow: hidden;
}

.background img {
  opacity: 0.3;
  -webkit-clip-path: url(#wave);
  clip-path: url(#wave);
  height: 30%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.game:hover .rank {
  -webkit-transform: translate(150%, -55%);
  transform: translate(120%, -55%);
}

.game:hover .front {
  -webkit-transform: translateY(-30%) scale(0.8);
  transform: translateY(-30%) scale(0.8);
}

.game:hover .front .name {
  -webkit-animation: gameName 250ms forwards;
  animation: gameName 250ms forwards;
}

.game:hover .front .resume {
  opacity: 0;
}

.game:hover .back {
  opacity: 1;
  -webkit-transition: opacity 150ms linear, -webkit-transform 250ms ease;
  transition: opacity 150ms linear, -webkit-transform 250ms ease;
  transition: transform 250ms ease, opacity 150ms linear;
  transition: transform 250ms ease, opacity 150ms linear,
    -webkit-transform 250ms ease;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.game:hover .back .technos {
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.game:hover .back .techno {
  font-size: 0.9rem;
}

.game:hover .back .name {
  font-size: 0.9em;
}

.game:hover .background {
  max-height: 20rem;
  border: 1px solid tan;
  -webkit-transition: var(--transition), opacity 100ms linear;
  transition: var(--transition), opacity 100ms linear;
  opacity: 1;
  -webkit-transform: scale(1.35, 1.3) translateY(5%);
  transform: scale(1.35, 1.3) translateY(5%);
}

.game:hover h3 {
  font-size: 1.2rem;
}

@-webkit-keyframes gameName {
  0% {
    text-align: left;
    opacity: 1;
  }
  20% {
    text-align: left;
    opacity: 0;
  }
  50% {
    text-align: center;
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    text-align: center;
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes gameName {
  0% {
    text-align: left;
    opacity: 1;
  }
  20% {
    text-align: left;
    opacity: 0;
  }
  50% {
    text-align: center;
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    text-align: center;
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}
/*# sourceMappingURL=main.css.map */
