* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

body {
  background: url(./assets/img/desktop2.jpg) no-repeat center center fixed;
  overflow-x: hidden;
  background-size: cover;
  /* height: 100vh; */
  width: 100%;
  height: auto;
}
/* @font-face {
  font-family: "Lobster Two";
  src: local("Lobster Two"),
    url(./assets/fonts/LobsterTwo-Regular.ttf) format("truetype");
} */
@font-face {
  font-family: "Courgette";
  src: local("Courgette"),
    url(./assets/fonts/Courgette-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Orbitron";
  src: local("Orbitron"),
    url(./assets/fonts/Orbitron-Regular.ttf) format("truetype");
}
.inputContact {
  transition: all 1s ease;
}
.fall {
  transform: translateY(4rem) rotateZ(5deg);
  /* transition: all 1s ease; */
  opacity: 0;
  width: 0px;
}
.clickFade {
  opacity: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}
